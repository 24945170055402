/** @format */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  box-sizing: border-box;
}
@font-face {
  font-family: "eurostileunicaseltpro";
  src: url(./font/eurostileunicaseltpro-maisfontes.2928/eurostileunicaseltpro.otf);
}
p {
  font-family: "Inter", sans-serif !important;
  margin-bottom: 0px !important;
}
.font-para {
  font-family: "Inter", sans-serif !important;
}
.font-head {
  font-family: "eurostileunicaseltpro";
}

::selection {
  background: #fff;
  color: #000;
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  background-color: #000;
}
a {
  text-decoration: none !important;
}
body {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  overflow-x: hidden !important;
  position: relative !important;
  background: linear-gradient(
    89deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(87, 84, 108, 0.47) 20%,
    rgba(87, 84, 108, 0.54) 81%,
    rgba(255, 255, 255, 1) 100%
  );
}
.navbar-toggler {
  outline: none !important;
  box-shadow: none !important;
}
.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none !important;
  position: relative;
  border-bottom: 2px solid #000;
  transition: all 300ms linear;
}

.navbar .navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #000;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar .navbar-toggler-icon:after {
  top: 8px;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}

.navbar-toggler {
  border: none !important;
}
.collapse {
  visibility: visible !important;
}
.btns {
  animation: btns 2s linear 0s infinite alternate both;
}
@keyframes btns {
  0% {
    transform: scale(1);
    opacity: 0.2;
  }

  100% {
    transform: scale(1.2);
    opacity: 1;
  }
}
#HeaderMap {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -20px;
  z-index: -4;
  opacity: 0.2;
}
.jxQFAb {
  width: 40px;
  height: 60px;
  position: relative;
  border: 2px solid rgb(0, 0, 0);
  box-sizing: border-box;
  border-radius: 54px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 110px auto 0px;
  cursor: pointer;
}
.biVOqy {
  height: 14px;
  width: 2px;
  background: rgb(0, 0, 0);
  position: absolute;
  bottom: 12px;
  border-radius: 54px;

  animation: 5s infinite normal none running bounceTop;
}
.img-bontop {
  img {
    transition: all 2s ease-in-out;
    width: 70px;
    display: block;
    animation: myAnim 2s ease-in-out 0s infinite alternate-reverse both !important;
  }
}
@keyframes myAnim {
  0% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(1.1);
  }
}
@keyframes bounceTop {
  0% {
    animation-timing-function: ease-in;
    opacity: 1;
    transform: translateY(-35px);
  }

  24% {
    opacity: 1;
  }

  40% {
    animation-timing-function: ease-in;
    transform: translateY(-24px);
  }

  65% {
    animation-timing-function: ease-in;
    transform: translateY(-12px);
  }

  82% {
    animation-timing-function: ease-in;
    transform: translateY(-6px);
  }

  93% {
    animation-timing-function: ease-in;
    transform: translateY(-4px);
  }

  25%,
  55%,
  75%,
  87% {
    animation-timing-function: ease-out;
    transform: translateY(0px);
  }

  100% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: translateY(0px);
  }
}
.filterimg1 {
  filter: drop-shadow(rgb(245, 172, 55) 0px 0px 69px);
  opacity: 0.7;
}
.filterimg2 {
  filter: drop-shadow(rgb(80, 175, 149) 0px 0px 69px);
  opacity: 0.7;
}
.filterimg3 {
  filter: drop-shadow(rgb(236, 236, 236) 0px 0px 33px);
  opacity: 0.7;
}
.filterimg4 {
  filter: drop-shadow(rgb(184, 183, 183) 0px 0px 69px);
  opacity: 0.7;
}
.filterimg5 {
  filter: drop-shadow(rgb(0, 106, 227) 0px 0px 69px);
  opacity: 0.7;
}
.filterimg6 {
  filter: drop-shadow(rgb(252, 252, 252) 0px 0px 69px);
  opacity: 0.7;
}
.filterimg7 {
  filter: rop-shadow(rgb(5, 16, 255) 0px 0px 69px);
  opacity: 0.7;
}
.filterimg8 {
  filter: drop-shadow(rgb(130, 71, 229) 0px 0px 69px);
  opacity: 0.7;
}
@-webkit-keyframes HeaderMapDots {
  0% {
    opacity: 0.7;
    -webkit-filter: brightness(1);
    filter: brightness(1);
    fill: #101010;
  }
  25% {
    opacity: 0.8;
    -webkit-filter: brightness(1.2);
    filter: brightness(1.2);
    fill: #57546c;
  }
  50% {
    opacity: 0.8;
    -webkit-filter: brightness(1.2);
    filter: brightness(1.2);
    fill: #57546c;
  }
  100% {
    opacity: 0.7;
    -webkit-filter: brightness(1);
    filter: brightness(1);
    fill: #101010;
  }
}

@keyframes HeaderMapDots {
  0% {
    opacity: 0.7;
    -webkit-filter: brightness(1);
    filter: brightness(1);
    fill: #101010;
  }
  25% {
    opacity: 0.8;
    -webkit-filter: brightness(1.2);
    filter: brightness(1.2);
    fill: #57546c;
  }
  50% {
    opacity: 0.8;
    -webkit-filter: brightness(1.2);
    filter: brightness(1.2);
    fill: #57546c;
  }
  100% {
    opacity: 0.7;
    -webkit-filter: brightness(1);
    filter: brightness(1);
    fill: #101010;
  }
}

@-webkit-keyframes IconImgAnimation {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-15px);
  }
  50% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes IconImgAnimation {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-15px);
  }
  50% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes firstIconAnimation {
  from {
    transform: translate(-30px, -30px);
    opacity: 0.2;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes firstIconAnimation {
  from {
    transform: translate(-30px, -30px);
    opacity: 0.2;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes secondIconAnimation {
  from {
    transform: translate(-30px, 0);
    opacity: 0.2;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes secondIconAnimation {
  from {
    transform: translate(-30px, 0);
    opacity: 0.2;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes thirdIconAnimation {
  from {
    transform: translate(-30px, 30px);
    opacity: 0.2;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes thirdIconAnimation {
  from {
    transform: translate(-30px, 30px);
    opacity: 0.2;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes fourthIconAnimation {
  from {
    transform: translate(30px, -30px);
    opacity: 0.2;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes fourthIconAnimation {
  from {
    transform: translate(30px, -30px);
    opacity: 0.2;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes fifthIconAnimation {
  from {
    transform: translate(30px, 0);
    opacity: 0.2;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes fifthIconAnimation {
  from {
    transform: translate(30px, 0);
    opacity: 0.2;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes sixthIconAnimation {
  from {
    transform: translate(30px, 30px);
    opacity: 0.2;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes sixthIconAnimation {
  from {
    transform: translate(30px, 30px);
    opacity: 0.2;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes seventhHeaderImg {
  from {
    transform: translate(30px, -30px);
    opacity: 0.2;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes seventhHeaderImg {
  from {
    transform: translate(30px, -30px);
    opacity: 0.2;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes eighthHeaderImg {
  from {
    transform: translate(-30px, 30px);
    opacity: 0.2;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes eighthHeaderImg {
  from {
    transform: translate(-30px, 30px);
    opacity: 0.2;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

#firstHeaderImg {
  -webkit-animation: firstIconAnimation 1s ease-out 1;
  animation: firstIconAnimation 1s ease-out 1;
}
#secondHeaderImg {
  -webkit-animation: secondIconAnimation 1s ease-out 1;
  animation: secondIconAnimation 1s ease-out 1;
}
#thirdHeaderImg {
  -webkit-animation: thirdIconAnimation 1s ease-out 1;
  animation: thirdIconAnimation 1s ease-out 1;
}
#fourthHeaderImg {
  -webkit-animation: fourthIconAnimation 1s ease-out 1;
  animation: fourthIconAnimation 1s ease-out 1;
}
#fifthHeaderImg {
  -webkit-animation: fifthIconAnimation 1s ease-out 1;
  animation: fifthIconAnimation 1s ease-out 1;
}
#sixthHeaderImg {
  -webkit-animation: sixthIconAnimation 1s ease-out 1;
  animation: sixthIconAnimation 1s ease-out 1;
}
#seventhHeaderImg {
  -webkit-animation: seventhHeaderImg 1s ease-out 1;
  animation: seventhHeaderImg 1s ease-out 1;
}
#eighthHeaderImg {
  -webkit-animation: eighthHeaderImg 1s ease-out 1;
  animation: eighthHeaderImg 1s ease-out 1;
}

.fadeAnimation {
  -webkit-animation: HeaderMapDots 4s ease-in-out infinite;
  animation: HeaderMapDots 4s ease-in-out infinite;
}
#fadeAnimation-first {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
#fadeAnimation-second {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
#fadeAnimation-third {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
#fadeAnimation-fourth {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
#fadeAnimation-fifth {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
#fadeAnimation-sixth {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
#fadeAnimation-seventh {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
#fadeAnimation-eighth {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
#fadeAnimation-ninth {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
#fadeAnimation-tenth {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.section4 {
  h2 {
    font-weight: bold;
    font-size: 28px;
    letter-spacing: 0.03em;
  }
  p {
    font-weight: normal;
    font-size: 19px;
    line-height: 28.5px;
    color: rgb(0, 0, 0);
    margin: 16px 0px 24px;
  }
}
// Finance Section Start
.finance_Sec {
  .card_main {
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: auto 100%;
    margin: 0px 0px 107px;
    padding: 32px;
    background: linear-gradient(95.64deg, #57546c4d -117.13%, #f1f1f877 89.73%),
      url("../src/media/images/border.svg") no-repeat !important;
    border-radius: 24px;

    .mobile {
      display: none;
    }

    h3 {
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
      background: -webkit-linear-gradient(
          top,
          rgb(0, 0, 0),
          rgba(0, 0, 0, 0.247)
        )
        text;
      -webkit-text-fill-color: transparent;
    }
    .tbl_head {
      h4 {
        border-bottom: 2px dotted rgba(0, 0, 0, 0.3);
        display: initial;
        cursor: default;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.6);
      }
    }
    .hr {
      border-bottom: 1px solid #32303f;
    }

    .img1 {
      width: 40px;
    }
    .row_item {
      display: flex;
      align-items: center;
      gap: 10px;
      h1 {
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
        color: rgb(0, 0, 0);
        margin: 0%;
      }
      p {
        font-weight: 500;
        font-size: 11px;
        letter-spacing: 0.03em;
        color: rgba(0, 0, 0, 0.6);
        margin: 0%;
      }
    }
    .protocol {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 126px;
      height: 34px;
      border: 2px solid #57546c;
      box-sizing: border-box;
      border-radius: 4px;
      span {
        font-family: Inter, sans-serif;

        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0.03em;
        color: rgb(0, 0, 0);
      }
    }
    .btn_main {
      display: flex;
      justify-content: flex-end;
      a {
        width: 190px;
        height: 42px;
        background: linear-gradient(
          95.64deg,
          #57546c -117.13%,
          #f1f1f8fd 89.73%
        );

        border-radius: 8px;
        font-weight: bold;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        text-decoration: none;
        color: rgb(0, 0, 0);

        &:hover {
          .icon {
            transform: translateX(5px);
          }
        }
      }
      .icon {
        margin-left: 10px;
        transition: all 300ms ease 0s;
      }
    }
  }
}
// Finance Section End

// Integration Section Start
.integration_sec {
  padding: 50px 0;
  h2 {
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 0.02em;
    margin-bottom: 31px;
  }
  .card_main {
    box-sizing: border-box;
    height: 131px;
    // width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 24px 19px;
    border-radius: 16px;
    background: linear-gradient(95.64deg, #57546c4d -117.13%, #f1f1f877 89.73%)
        no-repeat,
      url("media/images/IntegrationsCardBorder.0f7c4a81.svg") !important;
    background-repeat: no-repeat !important;
    h3 {
      font-weight: bold;
      font-size: 20px;
      letter-spacing: 0.02em;
    }
    p {
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.03em;
      color: rgb(0, 0, 0);
      margin: 0%;
      font-family: "Inter", sans-serif !important;
    }
  }
}

.farm-box {
  display: flex;
  max-width: 760px;
  flex-direction: column;
  overflow: hidden;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: auto 100%;
  margin: 0px 0px 107px;
  // padding: 32px;
  background: linear-gradient(95.64deg, #57546c4d -117.13%, #f1f1f877 89.73%),
    url("../src/media/images/border.svg") no-repeat !important;
  border-radius: 24px;
  h4 {
    font-size: 50px;
    text-transform: uppercase;
    text-align: center;
    line-height: 2.5rem;
    letter-spacing: 1px;
  }
  h5 {
    text-transform: uppercase;
    text-align: center;
    line-height: 2.5rem;
    letter-spacing: 1px;
  }
  li {
    color: #fff;
  }
  p.boxx {
    color: #fff;

    font-weight: 800;
    font-size: 14px;
    letter-spacing: 1px;
  }
  h1 {
    transform: translateY(2%);

    font-size: 48px;

    font-weight: 400;
    letter-spacing: -0.32px;
    text-align: center;
  }
  .quests-box {
    transition: box-shadow 0.3s ease-out 0s;
    transform-origin: 50% 50% 0px;
    transform: none;
    width: 100%;
    display: flex;
    align-items: center;
    height: 78px;
    -webkit-box-align: center;
    cursor: pointer;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid #57546c4d;
    // padding: 12px 16px;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      #57546c4d 50.24%,
      rgba(0, 0, 0, 0) 100%
    );
    p {
      letter-spacing: -0.02em;
      font-size: 17px;
      font-weight: 700;

      margin-top: 0;
      margin-bottom: 0rem;
    }
    .point {
      display: flex;
      position: absolute;
      width: 60%;

      border-radius: 4px;
      font-size: 13px;
      background: linear-gradient(
        95.64deg,
        #57546c4d -117.13%,
        #f1f1f877 89.73%
      );
      color: rgb(0, 0, 0);
      padding: 1px 0px;
      font-weight: bold;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      bottom: -8px;
    }
    &:hover {
      background: linear-gradient(
        90deg,
        rgba(73, 207, 219, 0) 0%,
        rgba(73, 207, 219, 0.001) 50.24%,
        rgba(73, 207, 219, 0) 100%
      );
    }
  }
  .done {
    filter: brightness(40%);
    cursor: auto;
    opacity: 0.5;
    background: linear-gradient(
      90deg,
      rgba(73, 207, 219, 0) 0%,
      rgba(73, 207, 219, 0) 50.24%,
      rgba(73, 207, 219, 0) 100%
    );
  }
  .logout {
    clip-path: none !important;
    max-width: 30%;
    font-size: 16px;
  }
  .user {
    -webkit-box-pack: center;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 20px;

    color: #fff !important;
    font-weight: 800;
  }
  button {
    position: relative;
    height: 48px;

    border: 0 !important;
    box-shadow: none !important;

    // padding: 0px 16px;
    -webkit-box-align: center;
    align-items: center;
    margin: 0 auto;
    -webkit-box-pack: center;
    justify-content: center;
    display: flex;
    font-size: 20px;

    font-weight: 800;
    &:disabled {
      cursor: not-allowed;
    }
  }
}
.codes {
  position: relative;
  height: 40px;
  border-radius: 40px;
  width: 30%;
  background-color: rgba(0, 0, 0, 0.582);
  padding: 10px 10px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  color: #fff !important;
  justify-content: center;
  cursor: pointer;
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.16);
  user-select: none;
  pointer-events: initial;
}
.Referred {
  position: relative;
  height: 40px;
  border-radius: 40px;
  width: 20%;
  background-color: rgba(0, 0, 0, 0.24);
  padding: 10px 10px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  color: #fff !important;
  justify-content: center;
  cursor: pointer;
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.16);
  user-select: none;
  pointer-events: initial;
}
// ========================================================
//  ======================= Modal =========================
// ========================================================

.modal-content {
  border: 1px solid #57546c4d !important;
  background: linear-gradient(
    95.64deg,
    #57546c -117.13%,
    #f1f1f8 89.73%
  ) !important;
  clip-path: polygon(
    0px 0px,
    100% 0px,
    100% 0px,
    100% 100%,
    100% 100%,
    0px 100%,
    0px 100%,
    0px 0px
  );
  .modal-body {
    padding: 30px;
  }
  button.fill {
    height: 48px;
    border: 0 !important;
    box-shadow: none !important;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    display: flex;
    font-family: "Inter", sans-serif !important;
    user-select: none;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    pointer-events: initial;
    color: #000000;
    &:disabled {
      cursor: not-allowed;
    }
  }
  button.outlines {
    position: relative;
    height: 48px;
    box-shadow: none !important;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-box-align: center;
    align-items: center;
    margin: 0 auto;
    -webkit-box-pack: center;
    justify-content: center;
    display: flex;
    user-select: none;
    font-family: "Inter", sans-serif !important;
    border: none !important;
    transform: translateY(2%);
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    text-align: center;
    pointer-events: initial;

    color: #000000;
    pointer-events: initial;
    &:disabled {
      cursor: not-allowed;
    }
  }
  .tweet {
    display: flex;
    align-items: center;
    width: 100%;
    background: rgba(0, 0, 0, 0.11);
    padding: 14px 16px;
    border-radius: 4px;
  }
  p {
    font-family: "Inter", sans-serif !important;
    font-weight: 400;
    transform: translateY(2%);
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87) 000;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 0rem;
    span {
      color: rgb(0, 0, 0);
      font-weight: bold;
    }
  }
}
.btn-close {
  display: block;
  box-shadow: none !important;
  transform: rotate(180deg) !important;
  padding: 7px 5px !important;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='black'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat !important;
}
.modal-header {
  position: relative;
  border-bottom: 0px !important;
}
.header-m {
  display: flex;
  width: 50%;
  height: 56px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: absolute;

  top: 0;
  left: 25%;

  h2 {
    padding-left: 28px;
    padding-right: 28px;
    font-weight: 700;
    font-size: 1.8rem;
    text-transform: uppercase;
    color: #000000;
  }
}

@media only screen and (max-width: 600px) {
  .codes {
    width: 80%;
  }
  .Referred {
    width: 80%;
  }
  .img-bontop {
    display: none;
  }
  #HeaderMap {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 70px;
    z-index: -1;
    opacity: 0.7;
    // width: 100%;
  }
}
.i::before {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  opacity: 20%;
  background: white;
  /*   background: #3B82F6; */
  /* Centering */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.i:hover:before {
  animation: anim-in 0.7s forwards ease-out;
}

@keyframes anim-in {
  100% {
    opacity: 0%;
    border-radius: 0;
    width: 600px;
    height: 600px;
  }
  0% {
    width: 0px;
    height: 0px;
    border-radius: 100%;
    opacity: 20%;
  }
}
@media screen and (max-width: 768px) {
  .maps {
    overflow: hidden !important;
  }
  .finance_Sec {
    .card_main {
      .laptop {
        display: none !important;
      }
      .mobile {
        display: block !important;
      }
      .tbl_head {
        display: none !important;
      }
      .btn_main {
        a {
          width: 135px !important;
        }
      }
      .row_item {
        display: flex;
        gap: 10px;
      }
    }
  }
  .integration_sec {
    .card_main {
      height: auto;
      img {
        width: 30px;
      }
    }
  }
}

@keyframes appear-slide-up {
  0% {
    transform: translateY(32px);
  }

  to {
    transform: translateY(0);
  }
}
.animate-appear-slide-up {
  animation: appear-slide-up 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}
.peer:focus ~ .peer-focus\:translate-y-\[11px\] {
  --tw-translate-y: 11px;
}
.peer:focus ~ .peer-focus\:scale-x-150,
.peer:focus ~ .peer-focus\:translate-y-\[11px\] {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.peer:focus ~ .peer-focus\:scale-x-150 {
  --tw-scale-x: 1.5;
}
.peer:focus ~ .peer-focus\:opacity-0 {
  opacity: 0;
}
.peer:focus-visible ~ .peer-focus-visible\:text-white {
  --tw-text-opacity: 1;
  color: rgb(var(--white) / var(--tw-text-opacity));
}
.peer:enabled ~ .peer-enabled\:cursor-pointer {
  cursor: pointer;
}
.peer:enabled:hover ~ .peer-enabled\:peer-hover\:text-white,
.peer:enabled ~ .peer-enabled\:hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(var(--white) / var(--tw-text-opacity));
}
.peer:disabled ~ .peer-disabled\:cursor-not-allowed {
  cursor: not-allowed;
}
.peer:disabled ~ .peer-disabled\:text-camo-300 {
  --tw-text-opacity: 1;
  color: rgb(var(--camo-300) / var(--tw-text-opacity));
}
.typography-brand-heading-3-caps {
  font-family: "eurostileunicaseltpro";
  font-size: 14px;

  letter-spacing: 0.9px;
  font-weight: 400;
  text-transform: uppercase;
}

@media (min-width: 1024px) {
  .typography-brand-heading-3-caps {
    font-size: 20px;

    letter-spacing: 1.2px;
    font-weight: 400;
  }
}

@media (min-width: 1920px) {
  .typography-brand-heading-3-caps {
    font-size: 24px;

    letter-spacing: 1.2px;
    font-weight: 400;
  }
}
.bor {
  border: 2px solid #32303f;
  box-shadow: none !important;
  outline: none !important;
}
.typography-brand-body-l {
  font-family: "eurostileunicaseltpro";

  font-size: 11px;

  letter-spacing: 0;
  font-weight: 400;
}
@media (min-width: 1024px) {
  .typography-brand-body-l {
    font-size: 14px;

    letter-spacing: 0;
    font-weight: 400;
  }
}

@media (min-width: 1920px) {
  .typography-brand-body-l {
    font-size: 16px;
    letter-spacing: 0;
    font-weight: 400;
  }
}
.\[\&\>\*\:only-child\]\:typography-brand-body-l > :only-child {
  font-family: "eurostileunicaseltpro";

  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0;
  font-weight: 400;
}
@media (min-width: 1024px) {
  .\[\&\>\*\:only-child\]\:typography-brand-body-l > :only-child {
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 0;
    font-weight: 400;
  }
}
@media (min-width: 1920px) {
  .\[\&\>\*\:only-child\]\:typography-brand-body-l > :only-child {
    font-size: 16px;
    line-height: 160%;
    letter-spacing: 0;
    font-weight: 400;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.borde {
  border: 1px solid rgb(20, 20, 20);
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.animate-timer {
  position: relative;
  animation: animatetop 1s infinite;
}
@keyframes animatetop {
  from {
    top: -50px;
  }
  to {
    top: 20px;
  }
}
#spinner-container {
  position: relative;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#spinner-container path {
  animation: spin 2s linear infinite;
}

.selected {
  background-color: transparent !important;
  transition: all 2s ease-in-out !important;
}
.btn_buy {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  font-family: "eurostileunicaseltpro" !important;
  color: #000;
  transition: all 2s ease-in-out !important;

  padding: 14px;
  width: 100%;
  text-align: center;
}
.btn_sell {
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  border-radius: 5px;
  transition: all 2s ease-in-out !important;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  font-family: "eurostileunicaseltpro" !important;
  color: #000;
  padding: 14px;
  width: 100%;
  text-align: center;
}
